import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";

import type { NavbarProps } from "../Navbar.types";

export const MobileNavbar = (props: NavbarProps) => {
  const { items } = props;

  const [openKeys, setOpenKeys] = useState([""]);

  const rootSubmenuKeys = items.map((item) => item.key);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (
      latestOpenKey === undefined ||
      rootSubmenuKeys.indexOf(latestOpenKey) === -1
    ) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      items={items}
    />
  );
};
