import styled from "styled-components";

export const PlaceHolderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  height: 150px;
  width: 100%;
  color: #777777;
`;
