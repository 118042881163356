import React, { useMemo, useState } from "react";
import { Builder } from "@builder.io/react";
import { Flex } from "@marbletech/core";
import { colors } from "@marbletech/core";
import { useIsMount } from "@marbletech/hooks";
import Script from "next/script";

import { analytics } from "@flare/analytics";

import { BiEventsNames } from "@/services/analytics/event-names";

import { interactiveElementsComponentsNames } from "../consts";
import * as bodyVariants from "../Text/Text.styled";

import { Faq } from "./Faq";
import type { FaqsProps } from "./Faqs.types";

export const Faqs = ({
  items,
  iconColor,
  borderColor,
  textColor,
  questionTextVariant,
  bodyVariant,
  event,
}: FaqsProps) => {
  const [openFaqId, setOpenFaqId] = useState<number | null>(null);
  const isMounted = useIsMount();
  const faqScheme = useMemo(
    () => ({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity:
        items?.map(({ answer, question }) => {
          return {
            "@type": "Question",
            name: question,
            acceptedAnswer: {
              "@type": "Answer",
              text: answer,
            },
          };
        }) ?? [],
    }),
    [items],
  );

  // fix hydration issue
  if (!isMounted) {
    return null;
  }

  const handleItemClick = ({
    isOpen,
    question,
    faqId,
  }: {
    faqId: number;
    isOpen: boolean;
    question: string;
  }) => {
    const eventData = {
      item_key: question,
      item_text: question,
      action: isOpen ? "expand" : "collapse",
    };

    analytics.track(BiEventsNames.WebPageClick, {
      ...eventData,
      location: event?.eventData?.location,
      element_name: "FAQ",
      click_type: eventData.action,
      click_text: question,
    });

    setOpenFaqId(isOpen ? faqId : null);
  };

  return (
    <>
      <Script id="faq-schema" type="application/ld+json">
        {JSON.stringify(faqScheme)}
      </Script>
      <Flex flexDirection="column" alignItems="stretch">
        {items?.map(({ answer, question }, index) => (
          <Faq
            key={`${index}-${question}`}
            question={question}
            answer={answer}
            onItemClicked={({ isOpen }) =>
              handleItemClick({ isOpen, question, faqId: index })
            }
            isOpen={openFaqId === index}
            iconColor={iconColor}
            borderColor={borderColor}
            textColor={textColor}
            questionTextVariant={questionTextVariant}
            bodyVariant={bodyVariant}
          />
        ))}
      </Flex>
    </>
  );
};

Builder.registerComponent(Faqs, {
  name: interactiveElementsComponentsNames.FAQS,
  inputs: [
    {
      name: "items",
      type: "list",
      subFields: [
        {
          name: "question",
          type: "string",
          defaultValue: "Question placeholder",
        },
        {
          name: "answer",
          type: "richText",
          defaultValue: "<p>Answer placeholder</p>",
        },
      ],
    },
    {
      name: "iconColor",
      type: "string",
      defaultValue: "b100",
      enum: [...Object.keys(colors)],
    },
    {
      name: "borderColor",
      type: "string",
      defaultValue: "b100",
      enum: [...Object.keys(colors)],
    },
    {
      name: "textColor",
      type: "string",
      defaultValue: "b100",
      enum: [...Object.keys(colors)],
    },
    {
      name: "questionTextVariant",
      type: "string",
      enum: [...Object.keys(bodyVariants)],
    },
    {
      name: "bodyVariant",
      type: "string",
      enum: [...Object.keys(bodyVariants)],
    },
    {
      name: "event",
      type: "object",
      subFields: [
        {
          friendlyName: "Data",
          name: "eventData",
          type: "object",
          subFields: [
            {
              friendlyName: "Location in the page, e.g. hero, fold_1, etc.",
              name: "location",
              type: "string",
            },
          ],
        },
      ],
    },
  ],
});
