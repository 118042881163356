import React, { useEffect, useState } from "react";

import { AnimatedBox, ANIMATION_TIME, Container } from "./FlippingText.styled";

export const FlippingText = ({ words }: { words: Array<string> }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, ANIMATION_TIME * 1000);
    return () => clearInterval(interval);
  }, [words]);

  return (
    <>
      {words[index].split(" ").map((word) => (
        <Container key={`${index}-${word}`}>
          <AnimatedBox>{word}</AnimatedBox>
        </Container>
      ))}
    </>
  );
};
