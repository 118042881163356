import React from "react";
import { Builder, withChildren } from "@builder.io/react";

import { analytics } from "@flare/analytics";

import { BiEventsNames } from "../../../services/analytics/event-names";
import { Link as LinkComponent } from "../../Link";
import type { LinkProps } from "../../Link/Link.types";

import { BuilderLinkProps } from "./Link.builder.props";

// Placeholder is used to better explain users how to use the button in Builder platfrom.
const Placeholder = () => <p>Insert element inside</p>;

const Link = ({
  children,
  href,
  pathname,
  attributes: builderAttributes,
  event,
  openInNewTab,
}: LinkProps) => {
  const handleClick = () => {
    if (event) {
      analytics.track(BiEventsNames.WebPageClick, {
        ...event?.eventData,
        click_url: href || pathname,
        click_type: "link",
      });
    }
  };

  return (
    <LinkComponent
      href={href}
      pathname={pathname}
      attributes={builderAttributes}
      onClick={handleClick}
      openInNewTab={openInNewTab}
    >
      {children ?? <Placeholder />}
    </LinkComponent>
  );
};

const LinkWithChildren = withChildren(Link);

Builder.registerComponent(LinkWithChildren, {
  name: "Link",
  noWrap: true,
  inputs: BuilderLinkProps,
});
