import { buttonTypes, sizes, variants } from "./Button.types";

export const ButtonBuilderProps = [
  { name: "href", type: "string" },
  { name: "pathname", type: "string" },
  {
    name: "variant",
    type: "string",
    defaultValue: "primary",
    enum: [...Object.keys(variants)],
  },
  {
    name: "size",
    type: "string",
    defaultValue: "lg",
    enum: [...Object.keys(sizes)],
  },
  {
    name: "text",
    type: "string",
    defaultValue: "Enter CTA text",
    helperText: "Button text (can be overwritten by inserting child elements)",
  },
  {
    name: "type",
    type: "string",
    defaultValue: "submit",
    enum: buttonTypes,
    helperText: "use submit for forms and button for links",
  },
  {
    name: "event",
    type: "object",
    subFields: [
      {
        friendlyName: "Data",
        name: "eventData",
        type: "object",
        subFields: [
          {
            friendlyName: "Location in the page",
            name: "location",
            type: "string",
            helperText: "Examples: hero, fold_1.",
          },

          {
            friendlyName: "CTA text",
            name: "cta_text",
            type: "string",
          },
        ],
      },
    ],
  },
];
