import React from "react";

import { PlaceHolderWrapper } from "./PlaceHolder.styled";

type PlaceHolderProps = {
  children?: string;
};

export const PlaceHolder = ({ children }: PlaceHolderProps) => {
  return <PlaceHolderWrapper>{children}</PlaceHolderWrapper>;
};
