import React from "react";
import { Builder } from "@builder.io/react";
import { colors } from "@marbletech/core";

import { FlippingText } from "@/components/FlippingText";

import { Heading } from "../Heading/Heading";
import { HeadingTypes } from "../Heading/Heading.types";

import { TextAnimationWrapper } from "./TextAnimation.styled";
import type { TextAnimationProps } from "./TextAnimation.types";

export const TextAnimation = ({
  items,
  color,
  type,
  cssOverrides,
  attributes: builderAttributes,
}: TextAnimationProps) => {
  const words = items?.map((item) => item.text);
  return (
    <TextAnimationWrapper
      variants={{
        exit: {
          opacity: 0,
          y: 20,
        },
        enter: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.5,
            type: "tween",
          },
        },
      }}
      initial="exit"
      animate="enter"
    >
      {words?.length && (
        <Heading
          color={color}
          type={type}
          customColor={cssOverrides?.customColor || ""}
          attributes={builderAttributes}
          balanceText
        >
          {words.length > 1 ? <FlippingText words={words} /> : words[0]}
        </Heading>
      )}
    </TextAnimationWrapper>
  );
};

Builder.registerComponent(TextAnimation, {
  name: "TextAnimation",
  noWrap: true,
  inputs: [
    {
      name: "items",
      type: "list",
      defaultValue: [
        { text: "divorce" },
        { text: "custody" },
        { text: "prenup" },
        { text: "adoption" },
        { text: "life" },
      ],
      subFields: [
        {
          name: "text",
          type: "string",
          defaultValue: "Text placeholder",
        },
      ],
    },
    {
      name: "type",
      type: "string",
      defaultValue: "H2",
      enum: [...Object.keys(HeadingTypes)],
    },
    {
      name: "color",
      type: "string",
      defaultValue: "b100",
      enum: [...Object.keys(colors)],
    },
    {
      friendlyName: "Override CSS",
      name: "cssOverrides",
      type: "object",
      advanced: true,
      subFields: [
        {
          name: "customColor",
          type: "color",
          helperText:
            "WARNING: if the custom color is selected, it will override the color field",
        },
      ],
    },
  ],
});
