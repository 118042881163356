import React from "react";

import { Faq as FaqComponent } from "../../Faq";
import * as bodyVariants from "../Text/Text.styled";

import { RichTextContainerWrapper as richTextContainerWrapper } from "./Faqs.styled";
import type { FaqProps, RichTextContainerProps } from "./Faqs.types";

// A component that wraps the styled component with custom styles as a <div> element.
export const RichTextContainer = (props: RichTextContainerProps) => {
  const Component = richTextContainerWrapper(props);
  return <Component {...props} />;
};

export const Faq = ({
  answer,
  question,
  textColor,
  questionTextVariant = "Text1",
  bodyVariant = "Text3",
  ...props
}: FaqProps) => {
  const Heading = bodyVariants[questionTextVariant];
  return (
    <FaqComponent
      question={
        // @ts-expect-error: No overload matches this call
        <Heading textAlign="start" color={textColor}>
          {question}
        </Heading>
      }
      {...props}
    >
      <RichTextContainer
        dangerouslySetInnerHTML={{ __html: answer }}
        textColor={textColor}
        bodyVariant={bodyVariant}
      />
    </FaqComponent>
  );
};
