import React from "react";
import { Builder, builder, withChildren } from "@builder.io/react";
import { Button as CoreButton } from "@marbletech/core";
import { useRouter } from "next/router";

import { analytics } from "@flare/analytics";

import { BiEventsNames } from "@/services/analytics/event-names";

import { navigationComponentsNames } from "../consts";

import { ButtonBuilderProps } from "./Button.builder.props";
import type { ButtonProps } from "./Button.types";

// Placeholder is used to better explain users how to use the button in Builder platform.
const Placeholder = () => <p>Insert element inside</p>;

export const Button = ({
  size,
  variant,
  children,
  event,
  href,
  pathname,
  onClick,
  attributes: builderAttributes,
  type = "submit",
  disabled = false,
  loading = false,
  text,
}: ButtonProps) => {
  const router = useRouter();

  const url = href || pathname;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    analytics.track(BiEventsNames.WebPageCtaClick, {
      ...event?.eventData,
      click_url: href || pathname,
    });

    event?.name && builder.track(event?.name);
    onClick?.(e);

    if (url) {
      router.push(url);
    }
  };

  const buttonContent = text || children;
  const { key, ...restBuilderAttributes } = builderAttributes || {};

  return (
    <CoreButton
      variant={variant}
      size={size}
      onClick={handleClick}
      type={type}
      disabled={disabled}
      loading={loading}
      key={key}
      {...restBuilderAttributes}
    >
      {buttonContent ?? <Placeholder />}
    </CoreButton>
  );
};

const ButtonWithChildren = withChildren(Button);

Builder.registerComponent(ButtonWithChildren, {
  name: navigationComponentsNames.BUTTON,
  noWrap: true,
  // @ts-expect-error: Type are wrong.
  inputs: ButtonBuilderProps,
});
