import React, { useMemo, useState } from "react";
import { Builder } from "@builder.io/react";
import Script from "next/script";

import { analytics } from "@flare/analytics";
import { cn } from "@flare/ui";

import type { TextProps } from "@/components/v2/Text/Text";
import { textConfig } from "@/components/v2/Text/Text";
import { BiEventsNames } from "@/services/analytics/event-names";

import { interactiveElementsComponentsNames } from "../consts";
import type { EventProps } from "../types";

import type { IconVariants } from "./FaqItem";
import { FaqItem, iconConfig } from "./FaqItem";

export type FaqsProps = {
  bodyVariant?: TextProps["variant"];
  builderState?: { state: Record<string, unknown> };
  className?: string;
  event?: EventProps;
  iconColor: IconVariants["color"];
  isOpen?: boolean;
  items: {
    answer: string;
    question: string;
  }[];
  questionTextVariant?: TextProps["variant"];
  textColor?: TextProps["color"];
};

export function FaqsV2({
  items,
  iconColor,
  textColor,
  questionTextVariant,
  bodyVariant,
  event,
  builderState,
  className,
}: FaqsProps) {
  // items is undefined during the ssr, not sure if this is builder side problem with binding
  const faqs = items || builderState?.state?.selectedFaqs;
  const [openFaqId, setOpenFaqId] = useState<number | null>(null);
  const faqScheme = useMemo(
    () => ({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity:
        faqs?.map(({ answer, question }) => {
          return {
            "@type": "Question",
            name: question,
            acceptedAnswer: {
              "@type": "Answer",
              text: answer,
            },
          };
        }) ?? [],
    }),
    [faqs],
  );

  const handleItemClick = ({
    isOpen,
    question,
    faqId,
  }: {
    faqId: number;
    isOpen: boolean;
    question: string;
  }) => {
    analytics.track(BiEventsNames.WebPageClick, {
      item_key: question,
      item_text: question,
      action: isOpen ? "expand" : "collapse",
      location: event?.eventData?.location,
      element_name: "FAQ",
      click_type: isOpen ? "expand" : "collapse",
      click_text: question,
    });

    setOpenFaqId(isOpen ? faqId : null);
  };

  return (
    <>
      <Script id="faq-schema" type="application/ld+json">
        {JSON.stringify(faqScheme)}
      </Script>
      <div className={cn("flex flex-col items-stretch", className)}>
        {faqs?.map(({ answer, question }, index) => (
          <FaqItem
            key={`${index}-${question}`}
            question={question}
            answer={answer}
            onItemClicked={({ isOpen }) =>
              handleItemClick({ isOpen, question, faqId: index })
            }
            isOpen={openFaqId === index}
            iconColor={iconColor}
            textColor={textColor}
            questionTextVariant={questionTextVariant}
            bodyVariant={bodyVariant}
          />
        ))}
      </div>
    </>
  );
}

Builder.registerComponent(FaqsV2, {
  name: interactiveElementsComponentsNames.FAQSV2,
  inputs: [
    {
      name: "items",
      type: "list",
      subFields: [
        {
          name: "question",
          type: "string",
          defaultValue: "Question placeholder",
        },
        {
          name: "answer",
          type: "richText",
          defaultValue: "<p>Answer placeholder</p>",
        },
      ],
    },
    {
      name: "iconColor",
      type: "string",
      defaultValue: "primary",
      enum: [...Object.keys(iconConfig.variants.color)],
    },
    {
      name: "textColor",
      type: "string",
      defaultValue: "blue",
      enum: [...Object.keys(textConfig.variants.color)],
    },
    {
      name: "questionTextVariant",
      type: "string",
      enum: [...Object.keys(textConfig.variants.variant)],
    },
    {
      name: "bodyVariant",
      type: "string",
      enum: [...Object.keys(textConfig.variants.variant)],
    },
    {
      name: "event",
      type: "object",
      subFields: [
        {
          friendlyName: "Data",
          name: "eventData",
          type: "object",
          subFields: [
            {
              friendlyName: "Location in the page, e.g. hero, fold_1, etc.",
              name: "location",
              type: "string",
            },
          ],
        },
      ],
    },
  ],
});
