import { BiEventsNames } from "@/services/analytics/event-names";

import { colors, shape, sizes, variants } from "./IconButton.types";

export const IconButtonBuilderProps = [
  { name: "href", type: "string" },
  { name: "pathname", type: "string" },
  {
    name: "variant",
    type: "string",
    defaultValue: "filled",
    enum: variants,
  },
  {
    name: "size",
    type: "string",
    defaultValue: "xs",
    enum: sizes,
  },
  {
    name: "color",
    type: "string",
    defaultValue: "primary",
    enum: colors,
  },
  {
    name: "shape",
    type: "string",
    defaultValue: "circle",
    enum: shape,
  },
  {
    name: "event",
    type: "object",
    subFields: [
      {
        name: "name",
        type: "string",
        enum: [...Object.keys(BiEventsNames)],
      },
      {
        friendlyName: "Data",
        name: "eventData",
        type: "object",
        subFields: [
          {
            friendlyName: "Location in the page",
            name: "location",
            type: "string",
            helperText: "Examples: hero, fold_1.",
          },

          {
            friendlyName: "CTA text",
            name: "cta_text",
            type: "string",
          },
        ],
      },
    ],
  },
];
