import { motion } from "framer-motion";
import styled from "styled-components";

import breakpoints from "../../../styles/breakpoints";

export const TextAnimationWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  @media (max-width: ${breakpoints.md}px) {
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
  }
`;
