import Image from "next/image";

import { useTranslation } from "@flare/i18n";

export const TrustPilotTemplate = {
  LOGO_FIRST: "Logo First",
  NUMBER_FIRST: "Number First",
  NUMBER_FIRST_WITH_DASH: "Number First With Dash",
};

type TrustPilotTemplateProps = {
  numberOfReviews: number;
  score: number;
} & TrustImageProps;

type TrustImageProps = {
  theme: "light" | "dark";
};

export const LogoFirstTemplate = ({
  theme,
  score,
  numberOfReviews,
}: TrustPilotTemplateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <TrustPilotImage theme={theme} />
      <span className="space-x-1.5 font-bold">
        <span>{score}</span>
      </span>
      <span>{t("TRUST_PILOT_V2_BASED_ON")}</span>
      <span className="font-bold">
        {numberOfReviews.toLocaleString()} {t("TRUST_PILOT_V2_REVIEWS")}
      </span>
    </>
  );
};

export const NumberFirstTemplate = ({
  theme,
  score,
  numberOfReviews,
}: TrustPilotTemplateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <span className="space-x-1.5 font-bold">
        <span>{score}</span>
      </span>
      <span>{t("TRUST_PILOT_V2_BASED_ON")}</span>
      <span className="font-bold">
        {numberOfReviews.toLocaleString()} {t("TRUST_PILOT_V2_REVIEWS")}
      </span>
      <TrustPilotImage theme={theme} />
    </>
  );
};

export const NumberFirstWithDashTemplate = ({
  theme,
  score,
  numberOfReviews,
}: TrustPilotTemplateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <span className="space-x-1">
        <span className="font-bold">{score}</span>
        <span>/</span>
        <span>5</span>
      </span>
      <span>{t("TRUST_PILOT_V2_BASED_ON")}</span>
      <span className="font-bold">
        {numberOfReviews.toLocaleString()} {t("TRUST_PILOT_V2_REVIEWS")}
      </span>
      <TrustPilotImage theme={theme} />
    </>
  );
};

const TrustPilotImage = ({ theme }: TrustImageProps) => {
  return (
    theme && (
      <Image
        alt="Trustpilot"
        src={`/next/images/trustpilot-${theme}.svg`}
        width={72}
        height={17}
        className="relative top-[-2px] inline-block"
      />
    )
  );
};
