import React from "react";

import { Path, StyledContainer, Text } from "./RotatingText.styled";
import type { RotatingTextProps } from "./RotatingText.types";

export function RotatingText({ fill }: RotatingTextProps) {
  return (
    <StyledContainer>
      <svg viewBox="0 0 100 100">
        <Path
          id="curve"
          d="
          M 10, 50
          a 40,40 0 1,1 80,0
          a 40,40 0 1,1 -80,0
          "
        />
        <Text fill={fill}>
          <textPath xlinkHref="#curve">
            Watch video · Watch video · Watch video · Watch video ·
          </textPath>
        </Text>
      </svg>
    </StyledContainer>
  );
}
