import styled, { keyframes } from "styled-components";

import type { RotatingTextProps } from "./RotatingText.types";

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledContainer = styled.div<RotatingTextProps>`
  font-size: 10px;
  font-family: Roobert;
  animation: ${rotating} 8s linear infinite;
  animation-play-state: paused;
  z-index: 1;
  width: 100%;
  min-width: 30px;
  height: 100%;
`;

export const Path = styled.path`
  fill: transparent;
`;

export const Text = styled.text`
  fill: ${({ fill }) => fill};
`;
