import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  .swiper {
    width: 100%;
  }
  
  .swiper-slide {
    width: auto;
  }
`;
