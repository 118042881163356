import React from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";

import { useAppContext } from "@/services/store/app-context";

import { desktopNavbarStyles } from "../Navbar.styled";
import type { NavbarProps } from "../Navbar.types";

export const DesktopNavbar = ({ items }: NavbarProps) => {
  const {
    navbar: { selectedNavbarItem, setSelectedNavbarItem },
  } = useAppContext();

  const onClick: MenuProps["onClick"] = (e) => {
    setSelectedNavbarItem(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[selectedNavbarItem]}
      mode="horizontal"
      items={items}
      style={desktopNavbarStyles}
    />
  );
};
