import type { BuilderComponentProps } from "@/types/builder";

const externalLinkPropFields: BuilderComponentProps = {
  friendlyName: "External Link",
  name: "href",
  type: "string",
};
const internalLinkPropFields: BuilderComponentProps = {
  friendlyName: "Internal Link",
  name: "pathName",
  type: "string",
};
const newTabPropFields: BuilderComponentProps = {
  name: "openInNewTab",
  type: "boolean",
  helperText: "open the link in a new tab",
};
const labelPropFields: BuilderComponentProps = {
  defaultValue: "Text",
  friendlyName: "Text",
  name: "label",
  type: "string",
};

const linkPropFields = [
  externalLinkPropFields,
  internalLinkPropFields,
  newTabPropFields,
];

export const NavbarBuilderProps = [
  {
    name: "items",
    type: "list",
    subFields: [
      labelPropFields,
      ...linkPropFields,
      {
        name: "children",
        subFields: [
          labelPropFields,
          ...linkPropFields,
          {
            name: "children",
            subFields: [labelPropFields, ...linkPropFields],
            type: "list",
          },
        ],
        type: "list",
      },
    ],
  },
];
