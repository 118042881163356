/**
 * Determines whether a given prop should be forwarded, based on custom and default validation rules.
 * This function is specifically designed for working with Builder.io, to identify props that start with 'builder-'.
 *
 * @function
 * @param {string | number } prop - The property name to validate.
 * @param {(prop: string | number ) => boolean} defaultValidatorFn - The default validation function for properties. Takes a prop name as an argument and returns a boolean.
 *
 * @returns {boolean} - True if the prop starts with 'builder-', or if it passes the default validation. Otherwise, returns false.
 *
 * @example
 * const result = shouldForwardBuilderPropsFn('builder-something', defaultValidation);
 * // result will be true because the prop starts with 'builder-'
 *
 * @example
 * const result = shouldForwardBuilderPropsFn('someProp', defaultValidation);
 * // result depends on what defaultValidation returns for 'someProp'
 */

export const shouldForwardBuilderPropsFn = (
  prop: string | number,
  defaultValidatorFn: (prop: string | number) => boolean,
) =>
  (typeof prop === "string" && prop.startsWith("builder-")) ||
  defaultValidatorFn(prop);
