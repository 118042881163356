import type { RefAttributes } from "react";
import { Button } from "@marbletech/core";
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import type { ThemedStyledProps } from "styled-components";
import styled, { keyframes } from "styled-components";

const open = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-collapsible-content-height)" },
});

const close = keyframes({
  from: { height: "var(--radix-collapsible-content-height)" },
  to: { height: 0 },
});

interface FaqContainerProps
  extends ThemedStyledProps<
    CollapsiblePrimitive.CollapsibleProps & RefAttributes<HTMLDivElement>,
    unknown
  > {
  $borderColor?: string;
}

export const FaqContainer = styled(
  CollapsiblePrimitive.Root,
)<FaqContainerProps>`
  && {
    border-top: 1px solid
      ${(props) => props.$borderColor && props.theme.colors[props.$borderColor]};
    padding: ${(props) => props.theme.space[5]}px 0px;

    &:last-child {
      border-bottom: 1px solid
        ${(props) =>
          props.$borderColor && props.theme.colors[props.$borderColor]};
    }
  }
`;

export const TitleLineLayout = styled(Button).attrs({ variant: "unstyled" })`
  && {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
`;

export const CollapseContentWrapper = styled(CollapsiblePrimitive.Content)`
  && {
    overflow: hidden;
    width: 100%;
    max-width: 1100px;
    animation-duration: 300ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    &[data-state="open"] {
      animation-name: ${open};
    }

    &[data-state="closed"] {
      animation-name: ${close};
    }
  }
`;
