import React, { useState } from "react";
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { cn } from "@flare/ui";

import { MinusIcon } from "@/components/icons/MinusIcon";
import { PlusIcon } from "@/components/icons/PlusIcon";
import type { TextProps } from "@/components/v2/Text/Text";
import { Text } from "@/components/v2/Text/Text";

import type { FaqsProps } from "./FaqsV2";

export const iconConfig = {
  variants: {
    color: {
      primary: "text-green-100",
      beige: "text-neutral-100",
      blue: "text-blue-100",
      gray: "text-blue-40",
      "light-gray": "text-blue-20",
      green: "text-green-100",
      white: "text-white-80",
    },
  },
};

const iconVariants = cva("ml-8 flex shrink-0", iconConfig);

export type IconVariants = VariantProps<typeof iconVariants>;

type TitleProps = {
  iconColor?: IconVariants["color"];
  isOpen: boolean;
};

export function Title({
  children,
  isOpen,
  iconColor,
}: React.PropsWithChildren<TitleProps>) {
  return (
    <CollapsiblePrimitive.Trigger asChild>
      <button className="flex w-full items-start justify-between">
        {children}
        <div className={cn(iconVariants({ color: iconColor }))}>
          {isOpen ? <MinusIcon width={24} /> : <PlusIcon width={24} />}
        </div>
      </button>
    </CollapsiblePrimitive.Trigger>
  );
}

type FaqItemProps = Omit<FaqsProps, "event" | "items"> & {
  answer: string;
  bodyVariant: TextProps["variant"];
  isOpen: boolean;
  onItemClicked: ({ isOpen }: { isOpen: boolean }) => void;
  question: React.ReactNode | string;
  questionTextVariant?: TextProps["variant"];
  textColor?: TextProps["color"];
};

export function FaqItem({
  answer,
  question,
  textColor,
  questionTextVariant = "text-1",
  bodyVariant = "text-3",
  onItemClicked,
  isOpen,
  iconColor,
}: FaqItemProps) {
  const [isClicked, setIsClicked] = useState(false);

  function toggle() {
    setIsClicked(true);
    onItemClicked({
      isOpen: !isOpen,
    });
  }
  return (
    <CollapsiblePrimitive.Root
      className="border-t border-blue-20 py-10 last:border-b last:border-blue-20"
      open={isOpen}
      onOpenChange={toggle}
    >
      <Title isOpen={isOpen} iconColor={iconColor}>
        <Text variant={questionTextVariant} color={textColor}>
          {question}
        </Text>
      </Title>
      <CollapsiblePrimitive.Content
        forceMount
        className={`w-full max-w-[1100px] overflow-hidden transition-all data-[state=closed]:animate-faq-close data-[state=open]:animate-faq-open ${isOpen && isClicked ? "" : "hidden"}`}
      >
        <Text
          className="mt-6 whitespace-normal text-base leading-6 md:text-lg md:leading-7 [&_*_a]:underline"
          color={textColor}
          variant={bodyVariant}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </CollapsiblePrimitive.Content>
    </CollapsiblePrimitive.Root>
  );
}
