import React from "react";
import { Builder } from "@builder.io/react";
import { colors } from "@marbletech/core";

import { interactiveElementsComponentsNames } from "../consts";

import { RotatingText } from "./components/RotatingText";
import { StyledImage, StyledWrapper } from "./RotatingTextButton.styled";
import type { RotatingTextProps } from "./RotatingTextButton.types";

export const RotatingTextButton = ({
  rotatingTextColor,
  image,
}: RotatingTextProps) => {
  return (
    <StyledWrapper>
      <RotatingText
        fill={(rotatingTextColor && colors[rotatingTextColor]) || colors.b100}
      />
      <StyledImage src={image} alt="play button" />
    </StyledWrapper>
  );
};

Builder.registerComponent(RotatingTextButton, {
  name: interactiveElementsComponentsNames.ROTATING_TEXT_BUTTON,
  inputs: [
    {
      name: "trigger",
      type: "uiBlocks",
      defaultValue: [],
    },
    {
      name: "rotatingTextColor",
      type: "text",
      defaultValue: "b100",
      enum: [...Object.keys(colors)],
    },
    {
      name: "image",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
      required: true,
      defaultValue:
        "https://cdn.builder.io/api/v1/image/assets%2F15f74e88630d41b6b43ece1273087b6f%2F494285bbefdc42ac8ddbed262c51801f",
    },
  ],
});
