import { theme } from "@marbletech/core";

export const spaceBetweenOptions = theme.space.map((space: number) =>
  space.toString(),
);

export const SliderBuilderProps = [
  {
    name: "autoplay",
    type: "boolean",
    defaultValue: true,
    helperText: "Enable autoplay",
  },
  {
    name: "desktopSpaceBetween",
    friendlyName: "Space between slides (desktop)",
    type: "string",
    defaultValue: 24,
    enum: spaceBetweenOptions,
  },
  {
    name: "mobileSpaceBetween",
    friendlyName: "Space between slides (mobile)",
    type: "string",
    defaultValue: 24,
    enum: spaceBetweenOptions,
  },
  {
    name: "includeMargins",
    friendlyName: "Include side margins",
    type: "boolean",
    defaultValue: true,
    helperText: "Add margin to the sides of the slider",
  },
  {
    name: "delay",
    type: "string",
    defaultValue: 2000,
    helperText: "Delay between transitions (in seconds)",
    enum: [
      {
        label: "1s",
        value: 1000,
      },
      {
        label: "2s",
        value: 2000,
      },
      {
        label: "3s",
        value: 3000,
      },
      {
        label: "4s",
        value: 4000,
      },
    ],
  },
  {
    name: "speed",
    type: "string",
    defaultValue: 1500,
    helperText: "Transition speed",
    enum: [
      {
        label: "Slow",
        value: 3000,
      },
      {
        label: "Medium",
        value: 1500,
      },
      {
        label: "Fast",
        value: 500,
      },
    ],
  },
  {
    name: "showPagination",
    type: "boolean",
    defaultValue: true,
    helperText: "Enable pagination - mobile only",
  },
  {
    name: "prevClassName",
    type: "string",
    friendlyName: "Previous button class name",
    helperText:
      "Add a class to the element you choose and refresh the page after each update.",
  },
  {
    name: "nextClassName",
    type: "string",
    friendlyName: "Next button class name",
    helperText:
      "Add a class to the element you choose and refresh the page after each update.",
  },
  {
    name: "event",
    type: "object",
    subFields: [
      {
        friendlyName: "Data",
        name: "eventData",
        type: "object",
        subFields: [
          {
            friendlyName: "Location in the page",
            name: "location",
            type: "string",
            helperText: "Examples: hero, fold_1.",
          },
        ],
      },
    ],
  },
];
