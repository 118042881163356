export const BuilderLinkProps = [
  { name: "href", type: "string", helperText: "External link" },
  {
    name: "pathname",
    type: "string",
    helperText: "Internal link (for example, to other pages in the domain) ",
  },
  {
    name: "openInNewTab",
    type: "boolean",
    helperText: "open the link in a new tab",
  },
  {
    name: "event",
    type: "object",
    subFields: [
      {
        friendlyName: "Data",
        name: "eventData",
        type: "object",
        subFields: [
          {
            friendlyName: "Location in the page, e.g. hero, fold_1, etc.",
            name: "location",
            type: "string",
          },
          {
            friendlyName: "Element name",
            name: "element_name",
            type: "string",
            helperText: "Unique element name. Examples: carousel, faqs.",
          },
          {
            friendlyName: "Link text",
            name: "click_text",
            type: "string",
          },
        ],
      },
    ],
  },
];
