import type { BuilderAttributes, EventProps } from "../types";

export enum variants {
  ghost = "ghost",
  primary = "primary",
  secondary = "secondary",
  square = "square",
  unstyled = "unstyled",
}

export enum sizes {
  lg = "lg",
  md = "md",
  sm = "sm",
  xl = "xl",
  xs = "xs",
}

export declare type ButtonVariant = keyof typeof variants;

export declare type ButtonSize = keyof typeof sizes;

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  attributes?: BuilderAttributes;
  event?: EventProps;
  href?: string;
  loading?: boolean;
  pathname?: string;
  size: ButtonSize;
  text?: string;
  variant: ButtonVariant;
}

type ButtonType = Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "type"
>["type"];

export const buttonTypes: Array<ButtonType> = ["button", "submit"];
