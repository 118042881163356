import React from "react";
import { Flex, MinusIcon, PlusIcon } from "@marbletech/core";
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";

import {
  CollapseContentWrapper,
  FaqContainer,
  TitleLineLayout,
} from "./Faq.styled";
import type { FaqProps, TitleProps } from "./Faq.types";

const Title = ({
  children,
  isOpen,
  iconColor,
}: React.PropsWithChildren<TitleProps>) => (
  <CollapsiblePrimitive.Trigger asChild>
    <TitleLineLayout>
      {children}
      <Flex flexShrink={0} marginLeft={4}>
        {isOpen ? (
          <MinusIcon color={iconColor} />
        ) : (
          <PlusIcon color={iconColor} />
        )}
      </Flex>
    </TitleLineLayout>
  </CollapsiblePrimitive.Trigger>
);

const Content = ({ children }: React.PropsWithChildren) => (
  <CollapseContentWrapper>{children}</CollapseContentWrapper>
);

export const Faq = ({
  children,
  question,
  onItemClicked = () => null,
  iconColor = "primary",
  borderColor = "primary",
  isOpen,
}: React.PropsWithChildren<FaqProps>) => {
  const toggle = () => {
    onItemClicked({
      isOpen: !isOpen,
    });
  };

  return (
    <FaqContainer
      open={isOpen}
      onOpenChange={toggle}
      $borderColor={borderColor}
    >
      <Title isOpen={isOpen} iconColor={iconColor}>
        {question}
      </Title>
      <Content>{children}</Content>
    </FaqContainer>
  );
};
