import { colors, typographyVariants } from "@marbletech/core";
import styled, { css } from "styled-components";

import breakpoints from "../../../styles/breakpoints";
import type { OverrideProps } from "../Heading/Heading.types";
import { shouldForwardBuilderPropsFn } from "../utils.builder";

const antialiasedCss = css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const H1 = styled("h1").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    ${typographyVariants.h1}
    ${antialiasedCss}
    font-family: Bogue;
    font-size: 88px;
    line-height: 96px;
    font-weight: 300;
    font-style: normal;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    white-space: ${({ whiteSpace }) => whiteSpace ?? "pre-wrap"};
    @media (max-width: ${breakpoints.md}px) {
      font-size: 40px;
      line-height: 48px;
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
    ${({ customColor }) => {
      return customColor ? `color: ${customColor}` : "";
    }};
  }
`;

export const H2 = styled("h2").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    ${typographyVariants.h2}
    ${antialiasedCss}
    font-family: Bogue;
    font-size: 56px;
    line-height: 64px;
    font-weight: 300;
    font-style: normal;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    white-space: ${({ whiteSpace }) => whiteSpace ?? "pre-wrap"};
    @media (max-width: ${breakpoints.md}px) {
      font-size: 32px;
      line-height: 40px;
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
    ${({ customColor }) => {
      return customColor ? `color: ${customColor}` : "";
    }};
  }
`;

export const H3 = styled("h3").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    ${typographyVariants.h3}
    ${antialiasedCss}
    font-family: Bogue;
    font-size: 48px;
    line-height: 60px;
    font-weight: 300;
    font-style: normal;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    white-space: ${({ whiteSpace }) => whiteSpace ?? "pre-wrap"};
    @media (max-width: ${breakpoints.md}px) {
      font-size: 32px;
      line-height: 40px;
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
    ${({ customColor }) => {
      return customColor ? `color: ${customColor}` : "";
    }};
  }
`;

export const H4 = styled("h4").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    ${typographyVariants.h4}
    ${antialiasedCss}
    font-family: Bogue;
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    font-style: normal;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    white-space: ${({ whiteSpace }) => whiteSpace ?? "pre-wrap"};
    @media (max-width: ${breakpoints.md}px) {
      font-size: 22px;
      line-height: 32px;
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
    ${({ customColor }) => {
      return customColor ? `color: ${customColor}` : "";
    }};
  }
`;

export const H5 = styled("h5").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    ${typographyVariants.h5}
    ${antialiasedCss}
    font-family: Bogue;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    font-style: normal;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    white-space: ${({ whiteSpace }) => whiteSpace ?? "pre-wrap"};
    @media (max-width: ${breakpoints.md}px) {
      font-size: 18px;
      line-height: 26px;
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
    ${({ customColor }) => {
      return customColor ? `color: ${customColor}` : "";
    }};
  }
`;
