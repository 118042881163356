export function MinusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      color="currentColor"
      {...props}
    >
      <rect
        x="6"
        y="11.25"
        width="12"
        height="1.5"
        rx="0.75"
        fill="currentColor"
      />
    </svg>
  );
}
