import { colors } from "@marbletech/core";
import styled, { css } from "styled-components";

import breakpoints from "../../../styles/breakpoints";
import { shouldForwardBuilderPropsFn } from "../utils.builder";

import type { OverrideProps } from "./Text.types";

const antialiasedCss = css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const Text1 = styled("p").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    font-family: Bogue;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    ${antialiasedCss}
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    @media (max-width: ${breakpoints.md}px) {
      font-size: 16px;
      line-height: 26px;
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
  }
`;

export const Text2 = styled("p").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    font-feature-settings:
      "liga" off,
      "ss02";
    font-family: Roobert;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    ${antialiasedCss}
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    @media (max-width: ${breakpoints.md}px) {
      font-size: 16px;
      line-height: 24px;
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
  }
`;

export const Text3 = styled("p").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    font-feature-settings:
      "liga" off,
      "ss02";
    font-family: Roobert;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    @media (max-width: ${breakpoints.md}px) {
      font-size: 16px;
      line-height: 24px;
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
  }
`;

export const Text4 = styled("p").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    font-feature-settings:
      "liga" off,
      "ss02";
    font-family: Roobert;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    @media (max-width: ${breakpoints.md}px) {
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
  }
`;

export const Text5 = styled("p").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    font-feature-settings:
      "liga" off,
      "ss02";
    font-family: Roobert;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    @media (max-width: ${breakpoints.md}px) {
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
  }
`;

export const Text6 = styled("p").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    font-feature-settings:
      "liga" off,
      "ss02";
    font-family: Roobert;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    @media (max-width: ${breakpoints.md}px) {
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
  }
`;

export const Label = styled("p").withConfig({
  shouldForwardProp: shouldForwardBuilderPropsFn,
})<OverrideProps>`
  && {
    font-feature-settings:
      "liga" off,
      "ss02";
    font-family: Roobert;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.6px;
    ${antialiasedCss}
    text-transform: uppercase;
    color: ${(props) => (props.color && colors[props.color]) || colors.b100};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    @media (max-width: ${breakpoints.md}px) {
      font-size: 12px;
      line-height: 20px;
      white-space: ${({ whiteSpace }) => whiteSpace ?? "normal"};
    }
  }
`;
