import React from "react";
import { Builder } from "@builder.io/react";
import { colors } from "@marbletech/core";
import { useIsMount } from "@marbletech/hooks";
import Script from "next/script";

import { interactiveElementsComponentsNames } from "../consts";

import type { TrustpilotProps } from "./Trustpilot.types";

// In addition to the colors defined in marbletech/core, the light and dark colors are also available,
// to match the light and dark theme colors
const textColors = {
  ...colors,
  light: "#fff",
  dark: "#191919",
};

export const Trustpilot = (props: TrustpilotProps) => {
  const { textColor, theme } = props;
  const ref = React.useRef(null);
  const isMounted = useIsMount();

  React.useEffect(() => {
    if (window.Trustpilot && ref.current) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [textColor, theme]);

  if (!isMounted) return null;

  return (
    <>
      <Script
        type="text/javascript"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-US"
        data-businessunit-id="5ee9bc358adfce0001e91218"
        data-style-width="100%"
        data-style-height="20px"
        data-review-languages="en"
        data-text-color={textColors[textColor]}
        data-stars="5"
        data-template-id="5419b637fa0340045cd0c936"
        data-theme={theme}
        style={{ marginRight: "auto" }}
      >
        <a
          href="https://www.trustpilot.com/review/themarbleway.com"
          target="_blank"
          rel="noopener noreferrer"
        ></a>
      </div>
    </>
  );
};

Builder.registerComponent(Trustpilot, {
  name: interactiveElementsComponentsNames.TRUSTPILOT,
  inputs: [
    {
      name: "textColor",
      type: "string",
      defaultValue: "w80",
      enum: [...Object.keys(colors), "light", "dark"],
      helperText: "Text color",
    },
    {
      name: "theme",
      type: "string",
      defaultValue: "dark",
      enum: ["light", "dark"],
      helperText: "Widget theme color",
    },
  ],
});
