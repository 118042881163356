import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    0% { top: 1.5em; }
    100% { top: 0; }
`;

const stay = keyframes`
    0% { top: 0; }
    100% { top: 0; }
`;

const fadeOut = keyframes`
    0% { top: 0; }
    100% { top: -1.5em; }
`;

export const Container = styled.span`
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  &:not(:last-child) {
    margin-right: 0.25em;
  }
`;

const FADE_TIME = 1.2;
const STAY_TIME = 2;
export const ANIMATION_TIME = 1.3 * FADE_TIME + STAY_TIME;

export const AnimatedBox = styled.span`
  position: relative;
  animation:
    ${fadeIn} ${FADE_TIME}s cubic-bezier(0.19, 1, 0.22, 1),
    ${stay} ${STAY_TIME}s ${FADE_TIME}s,
    ${fadeOut} ${FADE_TIME}s ${FADE_TIME + STAY_TIME}s
      cubic-bezier(0.19, 1, 0.22, 1);
  animation-fill-mode: forwards;
`;
