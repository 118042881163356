import styled from "styled-components";

import * as bodyVariants from "../Text/Text.styled";

import type { RichTextContainerProps } from "./Faqs.types";

// A higher-order function that wraps a styled component with custom styles as a <div> element.
// This function wraps the styled-component to pass the props to it's signature.
export const RichTextContainerWrapper = (props: RichTextContainerProps) => {
  return styled(bodyVariants[props.bodyVariant]).attrs({
    as: "div",
  })<RichTextContainerProps>`
    margin-top: 24px;
    color: ${(props) =>
      props.textColor
        ? props?.theme?.colors?.[props.textColor]
        : props?.theme?.colors?.b100};
    a {
      text-decoration: underline;
    }
  `;
};
