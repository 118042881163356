import React from "react";

import { analytics } from "@flare/analytics";

import { Link } from "@/components/Link";
import { BiEventsNames } from "@/services/analytics/event-names";

import type { NavbarItem } from "./Navbar.types";

function parseItem(item: NavbarItem, index: number) {
  const { label, href, pathName, openInNewTab, children } = item;
  const hasChildren = Array.isArray(children) && children.length > 0;

  function handleClick(item: NavbarItem) {
    analytics.track(BiEventsNames.WebPageClick, {
      location: "navbar",
      element_name: "navbar",
      click_text: item.label,
      click_url: item.href || item.pathName,
      click_type: "click",
    });
  }

  const labelWithLink = (
    <Link href={href} pathname={pathName} openInNewTab={openInNewTab}>
      {label}
    </Link>
  );
  const hasLink = href || pathName;

  return {
    label: hasLink ? labelWithLink : label,
    key: `${index}-${label?.replace(/[^a-zA-Z0-9]/g, "")}`,
    onClick: () => !hasChildren && handleClick(item),
  };
}

export const parseBuilderPropToMenuItems = (items: NavbarItem[] = []) =>
  items.map((item: NavbarItem, index) => {
    const hasChildren =
      Array.isArray(item.children) && item.children.length > 0;
    return {
      ...parseItem(item, index),
      children:
        hasChildren &&
        item.children?.map((child: NavbarItem, index) => {
          const hasChildren = child.children && child.children.length > 0;
          return {
            ...parseItem(child, index),
            children: hasChildren && child.children?.map(parseItem),
          };
        }),
    };
  });
