import React from "react";
import { Builder, withChildren } from "@builder.io/react";
import { useBreakpoint } from "@marbletech/hooks";
import {
  Autoplay,
  EffectCreative,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { analytics } from "@flare/analytics";

import { BiEventsNames } from "@/services/analytics/event-names";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/effect-fade";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { ClickTypes, interactiveElementsComponentsNames } from "../consts";
import { PlaceHolder } from "../PlaceHolder";
import { PaginationContainer } from "../Slider/Slider.styled";

import {
  SingleSliderBuilderProps,
  SliderEffects,
} from "./SingleSlider.builder.props";
import { GlobalStyle } from "./SingleSlider.styled";
import type { SingleSliderProps } from "./SingleSlider.types";

const fadeEffectProps = {
  fadeEffect: {
    crossFade: true,
  },
};

const creativeEffectProps = {
  creativeEffect: {
    prev: {
      shadow: true,
      translate: [0, 0, -300],
    },
    next: {
      translate: ["100%", 0, 0],
    },
  },
  grabCursor: true,
};

const paginationProps = {
  clickable: true,
  el: ".swiper-pagination-custom",
};

export function SingleSlider(props: SingleSliderProps) {
  const {
    attributes: builderAttributes,
    children,
    autoplay,
    delay,
    speed,
    event,
    effect,
    showPagination,
    mobileSpaceBetween,
    desktopSpaceBetween,
    prevClassName,
    nextClassName,
  } = props;

  const { key, ...restBuilderAttributes } = builderAttributes || {};

  const { mobile } = useBreakpoint();
  const spaceBetween = mobile ? mobileSpaceBetween : desktopSpaceBetween;

  const hasChildren = Array.isArray(children) && children.length > 0;

  const handleSwiperEvent = (eventType: string) => {
    analytics.track(BiEventsNames.WebPageClick, {
      location: event?.eventData?.location,
      element_name: "SingleSlider",
      click_type: eventType,
    });
  };

  return (
    <>
      <GlobalStyle />
      <Swiper
        onClick={() => handleSwiperEvent(ClickTypes.Click)}
        onSliderFirstMove={() => handleSwiperEvent(ClickTypes.Swipe)}
        key={key}
        effect={effect}
        pagination={showPagination && paginationProps}
        navigation={{
          prevEl: `.${prevClassName}`,
          nextEl: `.${nextClassName}`,
        }}
        slidesPerView={1}
        speed={speed}
        spaceBetween={spaceBetween}
        autoplay={
          autoplay && {
            delay,
            disableOnInteraction: false,
          }
        }
        modules={[Autoplay, EffectFade, EffectCreative, Navigation, Pagination]}
        {...(effect === SliderEffects.fade && fadeEffectProps)}
        {...(effect === SliderEffects.creative && creativeEffectProps)}
        {...restBuilderAttributes}
      >
        {hasChildren ? (
          React.Children.map(children, (child, index) => {
            return <SwiperSlide key={`${index}`}>{child}</SwiperSlide>;
          })
        ) : (
          <PlaceHolder>Insert Slides Inside</PlaceHolder>
        )}
        <PaginationContainer className="swiper-pagination-custom" />
      </Swiper>
    </>
  );
}

const SingleSliderWithChildren = withChildren(SingleSlider);

Builder.registerComponent(SingleSliderWithChildren, {
  name: interactiveElementsComponentsNames.SINGLE_SLIDER,
  noWrap: true,
  inputs: SingleSliderBuilderProps,
});
