import React from "react";
import { Builder } from "@builder.io/react";
import { useQuery } from "@tanstack/react-query";

import { interactiveElementsComponentsNames } from "../consts";

import {
  LogoFirstTemplate,
  NumberFirstTemplate,
  NumberFirstWithDashTemplate,
  TrustPilotTemplate,
} from "./trustpilot-templates";

const TRUST_PILOT_API_KEY = process.env
  .NEXT_PUBLIC_TRUST_PILOT_API_KEY as string;
const TRUST_PILOT_BUSINESS_UNITS_API = `https://api.trustpilot.com/v1/business-units/find?name=marble.co&apikey=${TRUST_PILOT_API_KEY}`;
const TRUST_PILOT_NAVIGATE_URL = "https://www.trustpilot.com/review/";

const TRUST_PILOT_RESPONSE_CACHE_TTL_MS = 86400000; // 1 day

const colors = {
  light: "#FDFBF8",
  dark: "#191919",
};

type TrustPilotResponse = {
  name: { identifying: string };
  numberOfReviews: {
    total: number;
  };
  score: {
    trustScore: number;
  };
};

type TrustPilotProps = {
  template: (typeof TrustPilotTemplate)[keyof typeof TrustPilotTemplate];
  textColor: keyof typeof colors;
  theme: "light" | "dark";
};

export const TrustpilotV2 = ({
  template,
  textColor,
  theme,
}: TrustPilotProps) => {
  const { isPending, error, data } = useQuery<TrustPilotResponse>({
    queryKey: ["trustpilot", { date: new Date().toDateString() }],
    queryFn: () =>
      fetch(TRUST_PILOT_BUSINESS_UNITS_API).then((res) => res.json()),
    enabled: !!TRUST_PILOT_BUSINESS_UNITS_API,
    staleTime: TRUST_PILOT_RESPONSE_CACHE_TTL_MS,
    gcTime: TRUST_PILOT_RESPONSE_CACHE_TTL_MS,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (isPending || error || !data) return null;

  const score = data?.score?.trustScore;
  const numberOfReviews = data?.numberOfReviews?.total;

  const identifying = data?.name?.identifying;
  const href = identifying ? `${TRUST_PILOT_NAVIGATE_URL}${identifying}` : "/";

  const templateProps = {
    theme,
    score,
    numberOfReviews,
  };

  let templateElement: JSX.Element;

  switch (template) {
    case TrustPilotTemplate.NUMBER_FIRST_WITH_DASH: {
      templateElement = <NumberFirstWithDashTemplate {...templateProps} />;
      break;
    }
    case TrustPilotTemplate.NUMBER_FIRST: {
      templateElement = <NumberFirstTemplate {...templateProps} />;
      break;
    }
    default:
      templateElement = <LogoFirstTemplate {...templateProps} />;
  }

  return (
    <a href={href} rel="noopener noreferrer" target="_blank">
      <div
        className="flex items-center space-x-2 font-helvetica text-sm/[14px]"
        style={{ color: colors[textColor] }}
      >
        {templateElement}
      </div>
    </a>
  );
};

Builder.registerComponent(TrustpilotV2, {
  name: interactiveElementsComponentsNames.TRUSTPILOTV2,
  inputs: [
    {
      name: "template",
      type: "string",
      defaultValue: TrustPilotTemplate.LOGO_FIRST,
      enum: Object.values(TrustPilotTemplate),
      helperText: "Template",
    },
    {
      name: "textColor",
      type: "string",
      defaultValue: "dark",
      enum: Object.keys(colors),
      helperText: "Text color",
    },
    {
      name: "theme",
      type: "string",
      defaultValue: "dark",
      enum: ["light", "dark"],
      helperText: "Widget theme color",
    },
  ],
});
