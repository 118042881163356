import React from "react";

import { StyledLink } from "./Link.styled";
import type { LinkProps } from "./Link.types";

export const Link = ({
  pathname = "",
  href,
  children,
  className,
  onClick,
  attributes: builderAttributes,
  openInNewTab,
}: LinkProps) => {
  const isExternalLink = !!href;
  const target = openInNewTab ? "_blank" : "_self";
  const { key, ...restBuilderAttributes } = builderAttributes || {};

  const ATag = (
    <a
      className={className}
      href={href}
      onClick={onClick}
      target={target}
      key={key}
      {...restBuilderAttributes}
    >
      {children}
    </a>
  );
  // Use Atag for external urls (when we received href prop) and NextLink for in-app navigation(using pathname).
  return isExternalLink ? (
    ATag
  ) : (
    <StyledLink
      legacyBehavior
      href={pathname}
      passHref
      target={target}
      key={key}
      {...restBuilderAttributes}
    >
      {ATag}
    </StyledLink>
  );
};
