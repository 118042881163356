import { Builder } from "@builder.io/sdk";

import {
  interactiveElementsComponentsNames,
  navigationComponentsNames,
} from "./consts";

function getBuilderGroupItems(groupNames: Record<string, string>) {
  return Object.values(groupNames).map((value) => ({ name: value }));
}

Builder.register("insertMenu", {
  name: "Interactive Elements",
  items: getBuilderGroupItems(interactiveElementsComponentsNames),
});

Builder.register("insertMenu", {
  name: "Navigation",
  items: getBuilderGroupItems(navigationComponentsNames),
});
