import React from "react";
import { Builder } from "@builder.io/react";
import Script from "next/script";

import { interactiveElementsComponentsNames } from "../consts";

export const TrustpilotReviewsWidgetGrid = () => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (window.Trustpilot && ref.current) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  });
  return (
    <>
      <Script
        type="text/javascript"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="539adbd6dec7e10e686debee"
        data-businessunit-id="5ee9bc358adfce0001e91218"
        data-style-height="600px"
        data-style-width="100%"
        data-theme="light"
        data-stars="3,4,5"
        data-review-languages="en"
      >
        <a
          href="https://www.trustpilot.com/review/marble.co"
          target="_blank"
          rel="noopener noreferrer"
        ></a>
      </div>
    </>
  );
};

Builder.registerComponent(TrustpilotReviewsWidgetGrid, {
  name: interactiveElementsComponentsNames.TRUSTPILOT_REVIEWS_WIDGET_GRID,
});
